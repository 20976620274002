import React from 'react';
import { useState } from 'react';
import { ReactComponent as NoMassaIcon } from '../myicon/modal_ico_nomassa.svg';
import './ModalFilter.css'; // Импортируем стили для модального окна
import 'rsuite/dist/rsuite.min.css';
import { DatePicker, Stack } from 'rsuite'; 
import ruRU from 'rsuite/locales/ru_RU';
import { CustomProvider } from 'rsuite';



const ModalFilter = ({ onClose, onCancel }) => {

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleSearch = () => {
    onClose({ startDate, endDate });
  };

  return (
    <div className="modal-overlay-filter">
    <div className="modal-filter">
      <CustomProvider locale={ruRU}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <div style={{ display: 'flex', alignItems: 'flex-end', gap: '8px' }}>
            <span style={{ alignSelf: 'center' }}>Дата с</span>
            <DatePicker style={{ width: 150, marginLeft: '9px' }} 
              placeholder="Дата" 
              value={startDate}
              onChange={setStartDate}
              />
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-end', gap: '8px' }}>
            <span style={{ alignSelf: 'center' }}>Дата по</span>
            <DatePicker style={{ width: 150 }} 
              placeholder="Дата по"
              value={endDate}
              onChange={setEndDate}
              />
          </div>
          {/* Кнопка закрытия */}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <button onClick={handleSearch}>Поиск</button>
            <button onClick={onCancel}>Отменить</button>
          </div>
        </div>
      </CustomProvider>
    </div>
  </div>
    
  );
};

export default ModalFilter;