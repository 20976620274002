import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import InputMask from 'react-input-mask';
import { isValid, parse, format } from 'date-fns';
import MaskedInput from 'react-text-mask';


import { ReactComponent as DelCircle } from '../components/myicon/del_circle.svg';
import { setProductsInMeal } from '../actions';
import { fetchDataFromDatabase } from '../services/DataService';
import BackButton from '../components/Button/BackButton';
import ButtonAddProduct from '../components/Button/ButtonAddProduct';
import ButtonCreatingMeal from '../components/Button/ButtonCreatingMeal';
import ModalOk from '../components/Meals/ModalAddMealOK';


import 'rsuite/dist/rsuite.min.css';
import { DatePicker, Input, TimePicker } from 'rsuite'; 
import ruRU from 'rsuite/locales/ru_RU';
import { CustomProvider } from 'rsuite';


const MealManagerView = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [data, setData] = useState([])

    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0]; // Преобразуем в формат 'yyyy-mm-dd'
    
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const [inputValueTime, setInputValueTime] = useState(formatTime(new Date()));
    const [inputValueTypemeal, setInputValueTypemeal] = useState('Перекус');
    const [InformationText, setInformationText] = useState('Продукты/блюда');
    const [PageTitle, setPageTitle] = useState('Создать прием пищи');
    const [date, setDate] = useState('');
    const [isValidDate, setIsValidDate] = useState(null);
    const [isValidTime, setIsValidTime] = useState(formatTime(new Date()));
    const [isModalOk, setModalOk] = useState(false);

    const [SumCaloria, setSumCaloria] = useState(100);
    const [isIdMeal, setIsIdMeal] = useState();
    
    

    const id_user = useSelector((state) => state.userId);
    const productInMeals = useSelector((state) => state.productInMeal);


    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

    // Если необходимо использовать состояния, можно раскомментировать и использовать
    const [startDate, setStartDate] = useState(new Date());
    const [time, setTime] = useState(new Date());
    const [snack, setSnack] = useState("Перекус");

  const commonStyles = {
    width: '100%', // ширина 100% для каждого компонента
    marginBottom: '10px', // отступ в 10px снизу
  };

    useEffect(() => {
        // Получаем текущую дату в формате дд/мм/гггг
        const currentDate = format(new Date(), 'dd/MM/yyyy');
        setDate(currentDate);
        setIsValidDate(null); // Сбрасываем состояние валидации
        console.log('productInMeals - ', productInMeals)
        setData(productInMeals);
      }, []); 

      useEffect(() => {
        dispatch(setProductsInMeal (data));

        const sumCalories = data.reduce((acc, item) => acc + item.sumcalorie, 0);
        const roundedCalories = sumCalories.toFixed(1);
        setSumCaloria(roundedCalories);
        // console.log('sumCalories - ', sumCalories);
        
      }, [data]); 

      useEffect(() => {
        console.log('isIdMeal - ', isIdMeal);

        if (!isIdMeal) return;
        const fetchData = async () => {
          const addProductUrl = 'https://www.foodandsports.ru/api/addProductToMealApi.php';
          for (const item of data) {
            console.log('Добавление продукта',isIdMeal.id,item.id,item.massa);
            const jsonData = JSON.stringify({ id_meal: isIdMeal.id, id_productfood: item.id, massa: item.massa });
          try {
            await fetchDataFromDatabase(addProductUrl, jsonData);
          } catch (error) {
            console.error(error);
          }
        };
       
    
      };
        fetchData();
        
      }, [isIdMeal]); 
    
      useEffect(() => {
        console.log('date - ', date);
      }, [date]); 

  

  const convertDateFormat = (dateString) => {
    // Разбиваем строку на части
    const [day, month, year] = dateString.split('/');
  
    // Создаем новую строку в нужном формате
    return `${year}-${month}-${day}`;
  };

// Проверка и валидация введенной даты

//   const handleChange = (event) => {
//     const inputDate = event.target.value;
     
//     if (isValidDateFormat(inputDate)) {
//       const parsedDate = parseDate(inputDate);
//       setIsValidDate(isValid(parsedDate));
//       setDate(inputDate);
//     } else {
//       setIsValidDate(false);
//     }
//   };

//   const isValidDateFormat = (dateString) => {
//     // Проверяем формат дд/мм/гггг
//     const regex = /^\d{2}\/\d{2}\/\d{4}$/;
//     return regex.test(dateString);
//   };

  const parseDate = (dateString) => {
    // Парсим дату, используя формат дд/мм/гггг
    return parse(dateString, 'dd/MM/yyyy', new Date());
  };

// ---------------------------------------------------------

  
// Проверка и валидация введенного времени 

//   const handleTimeChange = (e) => {
//     const newValue = e.target.value;
//     setInputValueTime(newValue);
//     setIsValidTime(validateTime(newValue));
//   };

//   const validateTime = (time) => {
//     // Регулярное выражение для проверки формата чч:мм
//     const regex = /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):([0-5][0-9])$/;
//     return regex.test(time);
//   };

// ---------------------------------------------------------


  const handleClick_AddProduct = () => {
    navigate('/mainmenu/menumeals/creatingmeal/addproductmeal');
  };


  const handleClick_CreatingMeal = async () => {

    const formattedTime = formatTime(time);

    try {
        console.log('Добавление meal')
        const url = 'https://www.foodandsports.ru/api/mealEntryApi.php'; 
        // const jsonData = JSON.stringify({ id_user: id_user, date:formattedDate, time:isValidTime, type:inputValueTypemeal, calorie:SumCaloria });
        const jsonData = JSON.stringify({ id_user: id_user, date:startDate, time: formattedTime, type:snack, calorie:SumCaloria });
        
        console.log("snack -->",snack)
        const id_meal = await fetchDataFromDatabase(url, jsonData);
        setIsIdMeal(id_meal);
  
      } catch (error) {
        console.error(error);
      }
      setModalOk(true);
      setTimeout(() => {
        setModalOk(false);
        navigate('/mainmenu/menumeals/');
      }, 1000);

      

  };

  const handleItemDelClick = async (index) => {
    // console.log(data[index]);

    setData(prevData => {
        // Создаем новый массив, исключая элемент по переданному индексу
        const newData = prevData.filter((_, i) => i !== index);
        return newData;
      });
  };



return (
  <div className="app-container">
 

<header className="headerStyle">
        <div className="header_contentStyle">
            <BackButton />
            <p className="header_textStyle">{PageTitle}</p>
        </div>    
</header>
    
<main>




<CustomProvider locale={ruRU}>
      <DatePicker style={commonStyles}
        placeholder="Дата"
        value={startDate}
        onChange={setStartDate}
      />
      
      <TimePicker style={commonStyles}
        placeholder="Время"
        value={time}
        onChange={setTime}
      />

      <Input style={commonStyles}
        placeholder="Перекус"
        value={snack}
        onChange={(value) => setSnack(value)}
      />
    </CustomProvider>


<div style={{ marginBottom: '5px', width: '300px'}}>Сумма калорий <span style={{ fontSize: '18px', color: '#B889E2' }}>{ SumCaloria} Ккал</span></div>

<div style={{ marginBottom: '5px',  width: '300px'}}>{InformationText}</div>

<ul className="container-item-productsearch">

{data.map((item, index) => (
<div key={index} className="item-productsearch">
<div className="item-name-productsearch">
  <div className="item-name-text-productsearch">
    {capitalizeFirstLetter(item.name)}
  </div>
  <div className="item-name-icon-productsearch" onClick={(e) => {
    e.stopPropagation(); // Остановить всплытие события
       if (!e.target.closest('.itemNameStyleIcon')) {
        //  handleStarClick(index);
          }
   }}>
 
 <DelCircle onClick={() => handleItemDelClick(index)}/>
  
  </div>   
</div>
<div className="item-calorie-productsearch" >
  {/* <div>{sentence(item.calorie)}</div> */}
  <div> {item.mass}гр - {item.sumcalorie.toFixed(1)} Ккал</div>
</div>
<div className="item-calorie-bold-productsearch">
  {/* <div>{Mass !== 0 && ProductMassCal(item.calorie, Mass)}</div> */}
</div>
</div>
))}
</ul>




</main>  

<footer style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
{isModalOk && <ModalOk/>}
<div onClick={handleClick_AddProduct} style={{ marginBottom: '10px' }}>
    <ButtonAddProduct />
</div>

<div onClick={handleClick_CreatingMeal} style={{ marginBottom: '10px' }}>
        <ButtonCreatingMeal />
      </div>
</footer>

</div>
);
};
export default MealManagerView;