import React, { useState } from "react";
// import ModalView from "../views/ModalView";

const ModalMealsController = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return { isModalOpen, openModal, closeModal };
};

export default ModalMealsController;