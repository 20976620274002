
class Meal {
    constructor(id, date, time, type, calorie) {
        this.id = id;
        this.date = date;
        this.time = time;
        this.type = type;
        this.calorie = calorie;

    }
}
export default Meal;



  