import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import { useNavigate } from 'react-router-dom';


import { fetchDataFromDatabase } from '../services/DataService';
import BackButton from '../components/Button/BackButton';

import MealItem from '../components/Meals/MealItem';

import { ReactComponent as Img_Chevron_Down } from '../components/myicon/chevron_down_icon.svg';
import { ReactComponent as Img_Chevron_Up } from '../components/myicon/chevron_up_icon.svg';
import { ReactComponent as DelCircle } from '../components/myicon/del_circle.svg';
import { ReactComponent as Ico_Calendar } from '../components/myicon/calendar.svg';

import ModalFilter from '../components/Meals/ModalFilter';

import 'rsuite/dist/rsuite.min.css';
import '../components/ProductDirectory/ProductSearch.css';
import '../components/Meals/ListMeals.css';

import useModalController from "../controllers/ModalMealsController";

import MealController from '../controllers/MealsController';

import { dateToDayOfWeek, formatDate, formatDateFilter} from '../utils/DateTimeTools';

const MealViewModel= () => {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const productInMeals = useSelector((state) => state.productInMeal);

    const mealController = new MealController();

    const id_user = useSelector((state) => state.userId);


    const [meals, setMeals] = useState([]);

    const updateMeals = async () => {
        console.log("Ура!!!");
       
        try {
            const data = await mealController.getMealsForUser(id_user);
            
            // Сначала отсортируем данные по дате и времени
            const sortedData = data.sort((a, b) => {
              if (a.date !== b.date) {
                  return a.date.localeCompare(b.date);
              }
              return a.time.localeCompare(b.time);
          });

          // Группировка данных сначала по дате, затем по времени
          const groupedData = sortedData.reduce((acc, curr) => {
              const { id, date, time, type, calorie } = curr;
              if (!acc[date]) {
                  acc[date] = [];
              }
              acc[date].push({ id, time, type, calorie});
              return acc;
          }, {});

          // Создание результирующего массива с добавлением суммы калорий
          const result = Object.keys(groupedData).map(date => {
              const meals = groupedData[date];
              const totalCaloriesSum = meals.reduce((sum, meal) => sum + parseFloat(meal.calorie), 0);// Подсчет суммы калорий
              const totalCalories = parseFloat(totalCaloriesSum).toFixed(2);

              return {
                  date,
                  totalCalories, // Добавляем сумму калорий
                  meals,
              };
          });

          console.log("result -->",result);
          
        //   setResult("123",result);
            setMeals(result);
            
        } catch (error) {
            console.error("Ошибка при загрузке блюд: ", error);
        }
        // console.log("meals -->",meals);
        
    };
    return { meals, updateMeals };
      };

export default MealViewModel;