import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Caloriefoods from './components/Caloriefoods';
import SplashScreen from './components/SplashScreen';
import Menu from './components/Menu';
import MenuProducts from './components/Menu/MenuProducts';
import MenuMeals from './components/Menu/MenuMeals';
import MenuWorkout from './components/Menu/MenuWorkout';
import ProductSearch from './components/ProductDirectory/ProductSearch';
import ProductFavorite from './components/ProductDirectory/ProductFavorite';
import CreatingMeal from './components/Meals/CreatingMeal';
import AddProductMeal from './components/Meals/AddProductMeal';
import AddProductMealFavorite from './components/Meals/AddProductMealFavorite';
import ListMeals from './components/Meals/ListMeals';
// import MealsView from './views/MealViewTest';
import MealsView from './views/MealView';
import EditMeal from './components/Meals/EditMeal';
import MealManagerView from './views/MealManagerView';

function Router() {
      return (
      <BrowserRouter>
       <div className="App">
            <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="caloriefoods" element={<Caloriefoods />} />
                  <Route path="splashscreen" element={<SplashScreen />} />
                  <Route path="mainmenu" element={<Menu />} />
                  <Route path="functional" element={<Menu />} />
                  <Route path="profile" element={<Menu />} />

                  <Route path="mainmenu/menuproducts" element={<MenuProducts />} />
                  <Route path="mainmenu/menuproducts/productfavorite" element={<ProductFavorite />} />
                  <Route path="mainmenu/menuproducts/productsearch" element={<ProductSearch />} />
                  <Route path="mainmenu/menumeals" element={<MenuMeals />} />
                  <Route path="mainmenu/menumeals/listmeals" element={<MealsView />} />
                  {/* <Route path="mainmenu/menumeals/listmeals" element={<ListMeals />} /> */}
                  {/* <Route path="mainmenu/menumeals/creatingmeal" element={<CreatingMeal />} /> */}
                  <Route path="mainmenu/menumeals/creatingmeal" element={<MealManagerView />} />
                  {/* <Route path="mainmenu/menumeals/editmeals" element={<EditMeal />} /> */}
                  <Route path="mainmenu/menumeals/editmeals/:mealId" element={<EditMeal />} />
                  <Route path="mainmenu/menumeals/creatingmeal/addproductmeal" element={<AddProductMeal />} />
                  <Route path="mainmenu/menumeals/creatingmeal/addproductmealfavorite" element={<AddProductMealFavorite />} />
                  <Route path="mainmenu/menuworkout" element={<MenuWorkout />} />
                  
                  
                  
            </Routes>
      </div>
    </BrowserRouter>
      )
}
const Home = () => {
      return <h1>Home Page</h1>;
    };

export default Router;