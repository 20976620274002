import React, { useState, useEffect, useRef , useMemo} from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import { useNavigate } from 'react-router-dom';



import BackButton from '../components/Button/BackButton';

import MealItem from '../components/Meals/MealItem';

import { ReactComponent as Img_Chevron_Down } from '../components/myicon/chevron_down_icon.svg';

import { ReactComponent as Img_Chevron_Up } from '../components/myicon/chevron_up_icon.svg';
import { ReactComponent as DelCircle } from '../components/myicon/del_circle.svg';
import { ReactComponent as Ico_Calendar } from '../components/myicon/calendar.svg';

import ModalFilter from '../components/Meals/ModalFilter';

import 'rsuite/dist/rsuite.min.css';
import '../components/Meals/ListMeals.css';

import useMealsViewModel from "../viewmodels/MealViewModel";
import useModalController from "../controllers/ModalMealsController";

import MealController from '../controllers/MealsController';

import { dateToDayOfWeek, formatDate, formatDateFilter} from '../utils/DateTimeTools';

const MealView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const productInMeals = useSelector((state) => state.productInMeal);

    const mealController = new MealController();

    const [date, setDate] = useState(new Date());
    

    // const [result, setResult] = useState([]);
    const [expandedItems, setExpandedItems] = useState({});
    const [PageTitle, setPageTitle] = useState('Приемы пищи');
    const TextCalorie = 'Ккал';
    const TextProbel = ' ';
    const id_user = useSelector((state) => state.userId);

    const [isModalFilterOpen, setModalFilterOpen] = useState(false);


    const {meals, updateMeals } = useMealsViewModel();
    const { isModalOpen, openModal, closeModal } = useModalController();

    const [isFilterApplied, setFilterApplied] = useState(false);
    const [infoFilter, setInfoFilter] = useState('');
    const [startDate, setStartDate] =  useState(new Date());
    const [endDate, setEndDate] =  useState(null);

    const result = useMemo(() => meals, [meals]);

useEffect(() => {
  updateMeals();
}, [id_user]);

  useEffect(() => {
    if (isFilterApplied) {
      if(endDate != null) {
        setInfoFilter("Период с " + startDate + " по " + endDate);
      }
    }
  },[isFilterApplied]);

  const toggleExpand = (index) => {
    setExpandedItems((prev) => ({
        ...prev,
        [index]: !prev[index]
    }));
}

const handleClick = (item) => {
  console.log('item)',item);
  navigate(`/mainmenu/menumeals/editmeals/${item.id}`, { state: item });
};


const handleModalFilterClose = (data) => {
  closeModal();
  setFilterApplied(true);

  const formattedStartDate = formatDateFilter(data.startDate);
  const formattedEndDate = formatDateFilter(data.endDate);

  setStartDate(formattedStartDate);
  setEndDate(formattedEndDate);
  
  console.log('Применить фильтр');
  console.log("Start Date:", formattedStartDate);
  console.log("End Date:", formattedEndDate);
}

return (
  <div className="app-container">
{isModalOpen && <ModalFilter onClose={handleModalFilterClose} onCancel={closeModal}/>}

<header className="headerStyle">
        <div className="header_contentStyle">
            <BackButton />
            <p className="header_textStyle">{PageTitle}</p>
            <Ico_Calendar style={{ marginLeft: 'auto', paddingRight: '15px'}} onClick={openModal} />
        </div>


</header>
    
<main>
  <div>{infoFilter}</div>

{result.map((item, index) => (
                 <div key={index}>
                     <div className="left-aligned-mlist" onClick={() => toggleExpand(index)}>
                         {/* <DateToDayOfWeek specificDate={item.date}/>{formatDate(item.date)} */}
                         {dateToDayOfWeek(item.date)} {formatDate(item.date)}
                         <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', fontSize: '16px', color: '#B889E2', width: '100%' }}>
                          ({item.totalCalories} {TextCalorie})
                      <div style={{ marginLeft: 'auto' }}>
                          {expandedItems[index] ? <Img_Chevron_Up /> : <Img_Chevron_Down />}
                      </div> 
                      </div>
                     
                     </div>
                     {expandedItems[index] && (
                 <ul>
                      {item.meals.map((meal, i) => (
                          <li key={i} className="li-style-mlist" onClick={() => handleClick(meal)}>
                         <MealItem time={meal.time} type={meal.type}  calorie={meal.calorie} />
                         <DelCircle style={{ marginRight: '0px' }} />
                         </li>
                     ))}
                 </ul>
              )}
                 </div>
             ))}
</main>  

</div>
);
};
export default MealView;