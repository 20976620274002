
// Преобразование даты в день недели
export const dateToDayOfWeek = (specificDate) => {
        const date = new Date(specificDate); // Создаем объект даты
        const dayOfWeek = date.toLocaleDateString('ru-RU', { weekday: 'long' }); // Получаем день недели
        const capitalizedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
  
        return (
            <div>
                {capitalizedDayOfWeek }&nbsp;
            </div>
    );
  }

  export const formatDate = (inputDate) => {
    const dateParts = inputDate.split('-'); // Разбиваем строку на части по знаку "-"
    const year = dateParts[0].slice(2); // Берем последние две цифры года
    const month = dateParts[1];
    const day = dateParts[2];
  
    return `${day}.${month}.${year}`; // Возвращаем отформатированную дату
  }


  export const formatDateFilter = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // месяцы начинаются с нуля
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };