import Meal from '../models/Meal';
import { fetchDataFromDatabase } from '../services/DataService';

class MealController {
    constructor () {
        this.meals = [];
    }

    async getMealsForUser(id_user) {
        try {
            const url = 'https://www.foodandsports.ru/api/mealgetall.php';
            const jsonData = JSON.stringify({ id_user: id_user });
            const data = await fetchDataFromDatabase(url, jsonData);
            // console.log("data -->", data)
            this.meals = data.map(item => new Meal(item.id, item.date, item.time, item.type, item.calorie));
        } catch (error) {
            console.error('Error fetching meal data:', error);
            return null; // or throw new Error('Failed to fetch meals');
        }

        return this.meals;
    }
}

export default MealController;